@import '../CommonStyles.scss';

.headline-container{
    padding-top: 140px;
    padding-bottom: 80px;
    background-color: #F9F6F4;
}
.switch-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8px;
    margin-top: 20px;
}
.msg-container{
    width: 767px;
    // height: 136px;
    margin-top: 100px;
    background-color: #E1EDED;
    display: flex;
    .update-info-text{
        padding: 32px;
    }
}
@media (max-width: 767px){
    .msg-container{
        width: fit-content;
        margin-top: 100px;
        display: flex;
        .update-info-text{
            padding: 16px;
        }
    }
}

.toggle-switch {
    position: relative;
    width: 64px;
    display: inline-block;
    vertical-align: middle;
    z-index: 1;
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    text-align: left;
    &-checkbox {
      display: none;
    }
    &-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 0 solid #bbb;
      border-radius: 8px;
      margin: 0;
    }
    &-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
      &:before,
      &:after {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        line-height: 34px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
      }
      &:before {
        content: attr(data-yes);
        padding-left: 10px;
        background-color: #fff;
        border: 2px solid #1ea2b1;
        border-radius: 8px;
        color: #1ea2b1;
      }
    }
    &-disabled {
      background-color: #ddd;
      cursor: not-allowed;
      &:before {
        background-color: #ddd;
        cursor: not-allowed;
      }
    }
    &-inner:after {
    content: attr(data-no);
      padding-right: 10px;
      background-color: #fff;
      border: 2px solid #8f9192;
      border-radius: 8px;
      color: #fff;
      text-align: right;
    }
    &-switch {
      display: block;
      width: 24px;
      margin: 5px;
      background: #8f9192;
      color: #8f9192;;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 28px;
      border: 0 solid #bbb;
      border-radius: 2px;
      transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
      .toggle-switch-inner {
        margin-left: 0;
      }
      .toggle-switch-switch {
        right: 0px;
        background: #1ea2b1;
        color: #1ea2b1;
      }
    }
  }
  .switch-label{
    margin-left: 8px;
  }
@import '../CommonStyles.scss';

.logout-btn {
    @include displayFlex();
    @include alignItems(center);
    @include justifyContent(center);
}

.counter-btn {
    @include displayFlex();
    @include alignItems(center);
    @include justifyContent(center);
    flex-direction: column;
    padding:5px;
}

#main-icon-sub-1{
    text-align: right;
}
@mixin displayFlex {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

@mixin justifyContent($value) {
  -moz-justify-content: $value;
  -webkit-justify-content: $value;
  -ms-justify-content: $value;
  justify-content: $value;
}

@mixin alignItems($value) {
  align-items: $value;
  -webkit-align-items: $value;
}

@mixin flexDirection($direction) {
  -webkit-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex($value) {
  -webkit-box-flex: $value;
  -ms-flex: $value;
  flex: $value;
}

$brixFontFamily: 'EONBrixSans';

body {
  margin: 0;
}

.color-separator {
  position: relative;
  z-index: 3;
  // top: -8px;
}

.cpo-preload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 24px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  margin: auto;
  max-width: 1200px;
}

.error-container {
  height: 176px;
  width: 584px;
  border-radius: 3px;
  display: block;
  margin: auto;
  font-family: $brixFontFamily;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-top: 80px;
  min-height: 530px;
}